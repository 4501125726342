import React from 'react';
import StoreForLife from './components/StoreForLife';



function App() {
  return (
    <div className='app_root row cont'>
      <StoreForLife />
    </div>
  );
}


export default App;
