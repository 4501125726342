import React from 'react';
import Logo from '../assets/img/logo.svg';
import LogoFooter from '../assets/img/logo-footer.svg';
import Progress from '../assets/img/work-in-progress.svg';
import Gpu from '../assets/img/gpu.svg';
import Kubernets from '../assets/img/kubernetes.svg';


const StoreForLife = () => {
    return (
        <div className='w-full'>
            <div className='contaner  '>
                <nav><img className='logo-g' src={Logo} alt="Logo" /></nav>
                <h1 >Unlock the power of GPUs with ease.<br />
                    Your gateway to limitless computing.</h1>
                <img className='logo-progress' src={Progress} alt="Work in progress" />
            </div>
            <div className='block-1'>
                <div className='container block-1-descr '>
                    <div className='flex flex-col block-1-text  alighn-left justify-left'>
                        <h2 className='font-bold'>Fast GPU</h2>
                        <p className='store '>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam labore a ipsam quasi quae, eveniet cumque neque, tempora earum quo similique sit repellendus! Veniam corrupti amet similique quisquam quaerat illo!
                        </p>
                    </div>
                    <div className='flex block-img flex-col justify-center'>
                        <img className='w-[240px] mx-auto ' src={Gpu} alt='/' />

                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='flex  justify-center'>
                    <h3>Iscriviti alla mailing list</h3>
                </div>
                <div className='justify-center flex'>
                    <input className='w-[440px] ' placeholder='Email' type="email" />

                </div>
            </div>
            <div className='block-2'>
                <div className='container block-1-descr '>
                    <div className='flex block-bottom block-img flex-col justify-center'>
                        <img className='w-[406px] mx-auto' src={Kubernets} alt='/' />
                    </div>
                    <div className='flex flex-col block-2-text  justify-left'>
                        <h2 className='font-bold'>Kubernets</h2>
                        <p className='store'>Lorem ipsum amet consectetur adipisicing elit. Aliquam labore a ipsam quasi quae, eveniet cumque neque, tempora earum quo similique sit repellendus! Veniam corrupti amet similique quisquam quaerat illo!</p>
                    </div>
                    <div className='flex  block-top block-img flex-col justify-center'>
                        <img className='w-[406px] mx-auto' src={Kubernets} alt='/' />
                    </div>
                </div>
            </div>
            <div className=' footer '>
                <div className=' footer-img justify-left'>
                    <img className=' mx-auto' src={LogoFooter} alt='/' />
                </div>
                <div className='footer-desc w-[350px]'>
                    <p className='text-footer' >
                        email: gigakube@gigakube.ch <br />
                        555 555 5555<br />
                        credit @ gigakube.io<br />
                    </p>
                </div>

            </div>


        </div>
    );
};

export default StoreForLife;
